export enum FedopsInteractionsNames {
  // CASHIER_MODAL_LOAD = 'CASHIER_MODAL_LOAD',
  // CASHIER_PAYMENT_PAGE_LOAD = 'CASHIER_PAYMENT_PAGE_LOAD',
  // CASHIER_PAYMENT_COMPLETE_PAGE_LOAD = 'CASHIER_PAYMENT_COMPLETE_PAGE_LOAD',
  // GET_WIDGET_DATA = 'GET_WIDGET_DATA',
  // VIDEO_PLAY_LIVE = 'VIDEO_PLAY_LIVE',
  // AUTOPLAY_LIVE = 'AUTOPLAY_LIVE',
  // DESKTOP_VIDEO_PLAY_COMPUTER = 'DESKTOP_VIDEO_PLAY_COMPUTER',
  // DESKTOP_VIDEO_PLAY_YOUTUBE = 'DESKTOP_VIDEO_PLAY_YOUTUBE',
  // DESKTOP_VIDEO_PLAY_VIMEO = 'DESKTOP_VIDEO_PLAY_VIMEO',
  // DESKTOP_VIDEO_PLAY_FACEBOOK = 'DESKTOP_VIDEO_PLAY_FACEBOOK',
  // DESKTOP_LIGHTBOX_APP_LOADED = 'DESKTOP_LIGHTBOX_APP_LOADED',
  // MOBILE_VIDEO_PLAY_COMPUTER = 'MOBILE_VIDEO_PLAY_COMPUTER',
  // MOBILE_VIDEO_PLAY_YOUTUBE = 'MOBILE_VIDEO_PLAY_YOUTUBE',
  // MOBILE_VIDEO_PLAY_VIMEO = 'MOBILE_VIDEO_PLAY_VIMEO',
  // MOBILE_VIDEO_PLAY_FACEBOOK = 'MOBILE_VIDEO_PLAY_FACEBOOK',
  MOBILE_OPEN_SLIDE_MENU = 'MOBILE_OPEN_SLIDE_MENU',
  MOBILE_OPEN_CHANNEL_VIEW = 'MOBILE_OPEN_CHANNEL_VIEW',
  MOBILE_OPEN_VIDEO_VIEW = 'MOBILE_OPEN_VIDEO_VIEW',
  MOBILE_LIGHTBOX_APP_LOADED = 'MOBILE_LIGHTBOX_APP_LOADED', // this interaction should be emitted in many places if we need it
}
