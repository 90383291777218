import { createLoggerActions } from '../../worker/actions/fedopsLogger';
import { VideoSources } from '../../redux/types';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import LAYOUTS_MAP from '@wix/wix-vod-shared/dist/src/common/layouts-map';

const SOURCES_INTERACTIONS_MAPPING = {
  [VideoSources.Computer]: 'VIDEO_PLAY_COMPUTER',
  [VideoSources.Youtube]: 'VIDEO_PLAY_YOUTUBE',
  [VideoSources.Vimeo]: 'VIDEO_PLAY_VIMEO',
  [VideoSources.Facebook]: 'VIDEO_PLAY_FACEBOOK',
  [VideoSources.Live]: 'VIDEO_PLAY_LIVE',
};

const noopActionCreator = () => () => {};

const noopInteraction = { start: noopActionCreator, end: noopActionCreator };

function createPlayVideoInteraction(createInteraction) {
  return ({ videoSource }) => {
    const interactionName = SOURCES_INTERACTIONS_MAPPING[videoSource];
    if (interactionName === undefined) {
      return noopInteraction;
    }

    return createInteraction(interactionName);
  };
}

function createPlayInteraction(createInteraction) {
  const getInteraction = (layout, videoItem) => {
    const videoSource = isLiveVideo(videoItem)
      ? VideoSources.Live
      : videoItem.videoSource;
    const interactionName = `PLAY_${videoSource.toUpperCase()}_${LAYOUTS_MAP[
      layout
    ].toUpperCase()}`;
    return createInteraction(interactionName);
  };

  return {
    start: (layout, videoItem) => getInteraction(layout, videoItem).start(),
    end: (layout, videoItem) => getInteraction(layout, videoItem).end(),
  };
}
function createNewFedopsLogger() {
  const { appLoaded, createInteraction } = createLoggerActions(undefined);
  return {
    appLoaded,
    getWidgetData: createInteraction('GET_WIDGET_DATA'),
    playVideo: createPlayVideoInteraction(createInteraction),
    autoplayLive: createInteraction('AUTOPLAY_LIVE'),
    play: createPlayInteraction(createInteraction),
    loadCashierModal: createInteraction('CASHIER_MODAL_LOAD'),
    loadCashierPaymentPage: createInteraction('CASHIER_PAYMENT_PAGE_LOAD'),
    loadCashierPaymentCompletePage: createInteraction(
      'CASHIER_PAYMENT_COMPLETE_PAGE_LOAD',
    ),
  };
}

export default createNewFedopsLogger();
